import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "hug-a-tree"
    }}><a parentName="h2" {...{
        "href": "/lessons/hug-a-tree/"
      }}>{`Hug a Tree`}</a></h2>
    <p>{`Start off with 5 minutes of Hug a Tree meditation.`}</p>
    <h2 {...{
      "id": "footwork-shuffle-step"
    }}>{`Footwork: Shuffle Step`}</h2>
    <p>{`Step forward in an empty stance.`}</p>
    <h2 {...{
      "id": "arms-pulling-wrist-pushing-wrist-lifting-wrist"
    }}>{`Arms: Pulling Wrist, Pushing Wrist, Lifting Wrist`}</h2>
    <p>{`Loosen up the wrists.`}</p>
    <h2 {...{
      "id": "posture-play-the-pipa"
    }}>{`Posture: Play the Pipa`}</h2>
    <p>{`Putting all the above together into the taiji posture called Play the Pipa.`}</p>
    <h2 {...{
      "id": "linking-the-postures"
    }}>{`Linking the Postures`}</h2>
    <p>{`Linking the moves we’ve learned into the first part of the 12 Move Form.`}</p>
    <ol>
      <li parentName="ol">{`Opening Move`}</li>
      <li parentName="ol">{`Brush Knee and Push x3`}</li>
      <li parentName="ol">{`Play the Pipa`}</li>
      <li parentName="ol">{`Repel Monkey x4`}</li>
      <li parentName="ol">{`Wave Hands Like Clouds x3`}</li>
    </ol>
    <p><a parentName="p" {...{
        "href": "/lessons"
      }}>{`Back to Lessons`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-4/"
      }}>{`Back to Lesson 4`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      